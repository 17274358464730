.more-section {
  margin-top: rem(100);
  margin-bottom: rem(70);

  @include desktop-sm-down {
    margin-top: rem(40);
    margin-bottom: rem(40);
  }

  .section-head {
    margin-bottom: rem(60);

    @include desktop-sm-down {
      margin-bottom: rem(30);
    }
  }
}

.more-swiper {

  .swiper {
    @include desktop-sm-down {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: rem(360);

    @include desktop-sm-down {
      width: rem(250);
    }

    &:not(:last-child) {
      margin-right: rem(30);

      @include desktop-sm-down {
        margin-right: rem(10);
      }
    }
  }


  .card-f__title {
    @include desktop-sm-down {
      font-size: rem(16);
      margin-bottom: rem(10);
    }
  }

  .card-f__img {
    @include desktop-sm-down {
      padding-top: 64.2%;
    }
  }

  .card-g__title {
    @include desktop-sm-down {
      font-size: rem(14);
      margin-bottom: rem(10);
    }
  }

  .card-g__img {
    @include desktop-sm-down {
      padding-top: 81.6%;
    }
  }

  &_poster {
    .swiper-slide {
      
      @include desktop-sm-up {
        width: rem(490);
      }
    }
    

    .card-c__img {
      @include desktop-sm-down {
        padding-top: 75%;
      }
    }

    .card-c__title {
      @include desktop-sm-down {
        font-size: rem(18);
      }
    }

    .card-c__labels {
      @include desktop-sm-down {
        position: absolute;
        top: rem(-20);
        left: rem(11);
        transform: translateY(-100%);
        z-index: 3;
        margin-top: 0;
      }
    }

    .card-c__label {
      @include desktop-sm-down {
        &:not(:first-child) {
          display: none;
        }
      }
    }

    .card-c__date {
      @include desktop-sm-down {
        font-size: rem(12);
      }
    }
  }
}
