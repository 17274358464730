.tooltip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: rem(18);
  height: rem(18);
  border-radius: 50%;
  background: #fff;

  &__title {
    font-weight: 600;
    font-size: rem(18);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #adbae1;
  }

  button {
    &:focus,
    &:hover {
      + .tooltip__main {
        display: block;
      }
    }
  }

  &__main {
    padding: rem(15) rem(30);
    position: absolute;
    border-radius: rem(30);
    font-size: rem(12);
    line-height: 150%;
    color: #123063;
    width: rem(220);
    background: #eff5fb;
    text-align: center;
    z-index: 2;
    white-space: normal;
    display: none;

    &.top {
      top: rem(-5);
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &.bottom {
      bottom: rem(-5);
      left: 50%;
      transform: translate(-50%, 100%);
    }

    &.left {
      left: rem(-5);
      top: 50%;
      transform: translate(-100%, -50%);
    }

    &.right {
      right: rem(-5);
      top: 50%;
      transform: translate(100%, -50%);
    }
  }
}
