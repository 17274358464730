.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @include desktop-sm-down {
    display: none;
  }

  &__inner {
    position: relative;
    background: #fff;
    border-radius: 0 0 rem(15) rem(15);
    overflow: hidden;
    max-width: rem(1530);
    margin: 0 auto;
    transition: .3s;
  }

  .container {
    padding-left: rem(30);
    padding-right: rem(30);
    transition: .3s;
  }


  &.fixed {

    .container {
      padding-left: rem($container-padding-lg);
      padding-right: rem($container-padding-lg);
    }

    .header__inner {
      filter: drop-shadow(0 0 rem(25) rgba(155, 193, 215, 0.55));
      max-width: rem(1625);
    }

    .header__top-inner {
      height: rem(50);
      
    }

    .header__top-nav ul li a {
      height: rem(50);
      font-size: rem(14);
    }

    .header__top-nav ul li a img {
      width: rem(29);
      margin-right: rem(20);
    }

    .header__search-btn {
      width: rem(22);
      height: rem(23);

      .icon {
        font-size: rem(22);
      }
    }

    .header__main-inner {
      height: rem(90);
    }

    .header__logo {
      max-width: rem(172);
    }

    .header__nav ul li a {
      font-size: rem(14);
    }

    .header__btn-entry {
      height: rem(44);
      font-size: rem(14);
    }

    .header__btn-lang {
      height: rem(44);
      font-size: rem(14);
    }
  }

  @import "includes/header/header__top";
  @import "includes/header/header__main";
  @import "includes/header/header__main-sub";
  @import "includes/header/header__search";
}
