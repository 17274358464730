@use "sass:math";
.icon-def-360-degrees {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-def-bank {
  font-size: math.div(17, 10) * 1rem;
  height: math.div(17, 21) * 1em;
}

.icon-def-burger {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 30) * 1em;
}

.icon-def-bus {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 21) * 1em;
}

.icon-def-calendar {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 17) * 1em;
}

.icon-def-check {
  font-size: math.div(9, 10) * 1rem;
  height: math.div(9, 13) * 1em;
}

.icon-def-checkers {
  font-size: math.div(10, 10) * 1rem;
  height: math.div(10, 25) * 1em;
}

.icon-def-chevron-down {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 25) * 1em;
}

.icon-def-chevron-left {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 15) * 1em;
}

.icon-def-chevron-right {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 15) * 1em;
}

.icon-def-chevron-top {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 25) * 1em;
}

.icon-def-close {
  font-size: math.div(11, 10) * 1rem;
  height: math.div(11, 11) * 1em;
}

.icon-def-cross {
  font-size: math.div(27, 10) * 1rem;
  height: math.div(27, 27) * 1em;
}

.icon-def-doc {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 21) * 1em;
}

.icon-def-download {
  font-size: math.div(12, 10) * 1rem;
  height: math.div(12, 12) * 1em;
}

.icon-def-enter-left {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-def-enter-right {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-def-entry {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 14) * 1em;
}

.icon-def-eye {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 22) * 1em;
}

.icon-def-eye-cross {
  font-size: math.div(22, 10) * 1rem;
  height: math.div(22, 22) * 1em;
}

.icon-def-fb {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 25) * 1em;
}

.icon-def-file {
  font-size: math.div(26, 10) * 1rem;
  height: math.div(26, 26) * 1em;
}

.icon-def-file2 {
  font-size: math.div(19, 10) * 1rem;
  height: math.div(19, 17) * 1em;
}

.icon-def-forbidden {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 29) * 1em;
}

.icon-def-ig {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 26) * 1em;
}

.icon-def-locate {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 16) * 1em;
}

.icon-def-map-marker {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 17) * 1em;
}

.icon-def-message {
  font-size: math.div(20, 10) * 1rem;
  height: math.div(20, 20) * 1em;
}

.icon-def-next {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 18) * 1em;
}

.icon-def-ok {
  font-size: math.div(26, 10) * 1rem;
  height: math.div(26, 26) * 1em;
}

.icon-def-p-parking {
  font-size: math.div(18, 10) * 1rem;
  height: math.div(18, 14) * 1em;
}

.icon-def-parking {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-def-people {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-def-present {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-def-prev {
  font-size: math.div(29, 10) * 1rem;
  height: math.div(29, 18) * 1em;
}

.icon-def-search {
  font-size: math.div(30, 10) * 1rem;
  height: math.div(30, 29) * 1em;
}

.icon-def-share {
  font-size: math.div(14, 10) * 1rem;
  height: math.div(14, 13) * 1em;
}

.icon-def-soccer-ball {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 23) * 1em;
}

.icon-def-sports-car {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 21) * 1em;
}

.icon-def-square {
  font-size: math.div(24, 10) * 1rem;
  height: math.div(24, 24) * 1em;
}

.icon-def-star {
  font-size: math.div(21, 10) * 1rem;
  height: math.div(21, 22) * 1em;
}

.icon-def-star2 {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 24) * 1em;
}

.icon-def-swap {
  font-size: math.div(17, 10) * 1rem;
  height: math.div(17, 21) * 1em;
}

.icon-def-ticket {
  font-size: math.div(23, 10) * 1rem;
  height: math.div(23, 20) * 1em;
}

.icon-def-ticket2 {
  font-size: math.div(16, 10) * 1rem;
  height: math.div(16, 16) * 1em;
}

.icon-def-vk {
  font-size: math.div(25, 10) * 1rem;
  height: math.div(25, 26) * 1em;
}

