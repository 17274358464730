.mob-menu {
  position: fixed;
  top: rem($header-h-m);
  bottom: 0;
  overflow: auto;
  left: 0;
  right: 0;
  background-color: #fff;
  padding-bottom: rem($bottomNav-h);
  z-index: 90;
  display: none;

  @include desktop-sm-up {
    display: none !important;
  }

  &__title  {
    font-weight: bold;
    font-size: rem(18);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #123063;
    margin-bottom: rem(13);
    margin-top: rem(20);
    
  }

  &__actions {
    display: flex;
  }

  &__btn-entry {
    height: rem(60);
    background: #11387a;
    padding-left: rem(22);
    padding-right: rem(22);
    color: #fff;
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-weight: bold;
    font-size: rem(14);
    line-height: rem(14);

    &:hover {
      background-color: #123063;
    }

    .icon {
      font-size: rem(14);
      color: inherit;
      margin-right: rem(22);
    }
  }

  &__btn-lang {
    width: rem(80);
    height: rem(60);
    background: #dfecf9;
    color: #11387a;
    font-weight: bold;
    font-size: rem(14);
    line-height: rem(14);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #123063;
      color: #fff;
    }
  }

  &__nav {
    > ul {
      padding: 0 rem($container-padding-md);

      > li {
        font-weight: bold;
        font-size: rem(14);
        line-height: rem(14);
        color: #123063;
        border-bottom: rem(1) solid #e7f1fb;

        > a {
          color: inherit;
          display: flex;
          align-items: center;
          height: rem(50);
        }

        &.active {
          .icon-chevron-down {
            color: #00a3da;
            transform: rotate(180deg);
          }
        }

        > ul {
          margin: 0 rem(-$container-padding-md);
          padding: 0 rem($container-padding-md);
          background: #e7f1fb;
          display: none;

          > li {
            font-weight: 550;
            font-size: rem(14);
            color: #123063;

            &:not(:last-child) {
              border-bottom: rem(1) solid #f2f7fb;
            }

            > a {
              height: rem(50);
              display: flex;
              align-items: center;
              color: inherit;
              padding: 0 rem($container-padding-md);
            }
          }
        }
      }
    }

    .icon-chevron-down {
      margin-left: auto;
      font-size: rem(12);
      margin-right: rem(22);
    }
  }

  &__nav-ico {
    width: rem(40);
    margin-right: rem(10);
  }

  [src*="ticket.svg"] {
    width: rem(20);
  }

  .icon-def-ticket {
    font-size: rem(20);
  }

  .icon-def-star {
    font-size: rem(22);
  }

  .icon-def-doc {
    font-size: rem(21);
  }

  .icon-def-map-marker {
    font-size: rem(17);
  }

  .icon-def-bank {
    font-size: rem(21);
  }

  .icon-def-message {
    font-size: rem(20);
  }
}
