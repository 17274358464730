.article {
  ul {
    padding-left: rem(25);

    li {
      &:not(:last-child) {
        margin-bottom: rem(25);
      }

      .dot {
        color: #00A3DA;
      }
    }
  }
}

.article-accordions {
  margin-top: rem(50);

  @include desktop-sm-down {
    margin-top: rem(40);
  }
}

.info-txt {
  padding: rem(35) rem(55);
  background: #E0F2FF;
  border-left: rem(8) solid #00A3DA;
  margin: rem(60) 0;
  font-weight: 600;
  font-size: rem(24);
  line-height: 150%;
  color: #123063;

  @include desktop-sm-down {
    margin: rem(40) 0;
    padding: rem(20) rem(17);
    font-size: rem(16);
    border-left-width: rem(4);
  }
}



.article-tickets {
  h3 {
    @include desktop-sm-down {
      font-size: rem(16);
    }
  }
}

.article-organizers {
  h3 {
    @include desktop-sm-down {
      font-size: rem(18);
    }
  }
}

.article-excursion {
  margin: rem(50) 0;

  @include desktop-sm-down {
    margin: rem(30) 0;
  }
}

.article-swiper {

  .swiper {
    margin: 0 rem(-15);
    width: auto;

    @include desktop-sm-down {
      margin: 0 rem(-5);
      overflow: visible;
    }
  }

  .swiper-slide {
    width: 100%;
    padding: 0 rem(15);
    box-sizing: border-box;


    @include desktop-sm-down {
      padding: 0 rem(5);
      width: 82%;
    }

    &.w-50 {
      @include desktop-sm-up {
        width: 50%;
      }
    }

    &.w-33 {
      @include desktop-sm-up {
        width: 33.33%;
      }
    }

    &.w-30 {
      @include desktop-sm-up {
        width: 30%;
      }
    }

    &.w-25 {
      @include desktop-sm-up {
        width: 25%;
      }
    }

    &.w-20 {
      @include desktop-sm-up {
        width: 20%;
      }
    }
  }

  &__nav {
    position: absolute;
    bottom: rem(50);
    right: rem(70);
    z-index: 2;
    display: flex;
    align-items: center;

    @include desktop-sm-down {
      display: none;
      right: rem(25);
      bottom: rem(16);
    }
  }

  &__prev,
  &__next {
    width: rem(45);
    height: rem(45);
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0194d8;
    cursor: pointer;
    transition: 0.3s;

    @include desktop-sm-down {
      width: rem(25);
      height: rem(25);
    }

    &:hover {
      color: #fff;
      background: #11387a;
    }

    &:not(:last-child) {
      margin-right: rem(15);

      @include desktop-sm-down {
        margin-right: rem(8);
      }
    }

    .icon {
      font-size: rem(12);

      @include desktop-sm-down {
        font-size: rem(7);
      }
    }
  }

  &__prev {
    margin-right: rem(15);
  }

  .card-h {
    @include desktop-sm-up {
      height: rem(500);
    }
  }
}

.hall-info {
  display: flex;

  @include desktop-sm-down {
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: rem(30);

      @include desktop-sm-down {
        margin-right: 0;
        margin-bottom: rem(10);
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(22);
    line-height: rem(28);
    letter-spacing: 0.02em;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(20);
    }
  }

  &__desc {
    font-weight: 600;
    font-size: rem(14);
    line-height: 140%;
    color: #9BC1D7;

    @include desktop-sm-down {
      font-size: rem(12);
    }
  }

  &__ico {
    background: radial-gradient(92% 92% at 0% 100%, #0194D8 0%, #4A36C6 100%), #123063;
    border-radius: rem(15);
    color: #fff;
    font-size: rem(23);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(50);
    height: rem(50);
    margin-right: rem(30);
    flex-shrink: 0;
  }
}

.about-event {
  margin-top: rem(40);
  margin-bottom: rem(75);

  @include desktop-sm-down {
    margin-top: rem(20);
    margin-bottom: rem(25);
  }

  &:last-child {
    margin-bottom: 0;
  }
}


@import "includes/article/all";