.hero-section {
  position: relative;
  background: linear-gradient(239.61deg, #11387a 32.99%, #0194d8 99.3%);
  min-height: rem(470);
  z-index: 5;
  border-radius: 0 0 rem(30) rem(30);

  @include desktop-sm-down {
    min-height: rem(230);
    border-radius: 0;
    // overflow: visible;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: rem(15);
      background: #ffffff;
      border-radius: rem(15) rem(15) rem(0) rem(0);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 0 rem(30) rem(30);
    overflow: hidden;
    z-index: 1;

    @include desktop-sm-down {
      border-radius: 0;
    }

    .container {
      position: relative;
      height: 100%;
    }

    &::before {
      content: "";
      height: 216%;
      background: linear-gradient(239.61deg, #11387a 32.99%, #0194d8 99.3%);
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding-bottom: rem(40);

    @include desktop-sm-down {
      padding-bottom: rem(55);
    }
  }

  h1,
  h2, 
  h3 {
    color: #fff;
    margin: 0;
    max-width: rem(880);
  }

  h3 {
    font-size: rem(24);

    @include desktop-sm-down {
      font-size: rem(16);
    }
  }

  p {
    color: #fff;
    max-width: rem(880);
  }

  &__desc {
    font-weight: 600;
    font-size: rem(28);
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #ffffff;
    max-width: rem(880);

    @include desktop-sm-down {
      font-size: rem(14);
    }

    &.sm {
      font-size: rem(24);
      line-height: 150%;

      @include desktop-sm-down {
        font-size: rem(16);
      }
    }

    &.lite {
      font-weight: normal;
    }
  }

  &__head {
    display: flex;
    flex-wrap: wrap;

    @include desktop-sm-down {
      flex-direction: column;
    }

    .share-block {
      align-self: flex-start;

      @include desktop-sm-down {
        margin-left: 0;
        margin-top: rem(30);
      }
    }

    + .share-block {
      margin-top: rem(30);
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-15) rem(-30);

    &--end {
      align-items: flex-end;
    }

    @include desktop-sm-down {
      flex-direction: column;
      margin: 0 rem(-$container-padding-md) rem(-40);
    }

    &.row_size_1 {
      .hero-section__col {
        &:nth-child(1) {
          @include desktop-sm-up {
            width: calc(100% - #{rem(390)});
          }
        }
    
        &:nth-child(2) {
          @include desktop-sm-up {
            width: rem(390);
          }
        }
      }
    }
  }

  &__col {
    width: 100%;
    padding: 0 rem(15);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      padding: 0 rem($container-padding-md);
      margin-bottom: rem(30);
    }

    &:nth-child(1) {
      @include desktop-sm-up {
        width: calc(100% - #{rem(520)});
      }
    }

    &:nth-child(2) {
      @include desktop-sm-up {
        width: rem(520);
      }
    }
  }


  &__title {
    max-width: rem(1010);
    margin-right: rem(30);
    margin-right: auto;

    h1, h2 {
      @include desktop-sm-up {
        font-weight: 600;
        font-size: rem(40);
        line-height: 120%;
      }
    }
  }

  .back-btn {
    margin-top: rem(40);
    margin-bottom: rem(40);

    @include desktop-sm-down {
      margin-top: 0;
      margin-bottom: rem(30);
    }
  }

  &__date {
    font-style: italic;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #FFFFFF;
    margin-bottom: rem(25);

    @include desktop-sm-down {
      font-weight: 500;
      margin-bottom: rem(15);
    }
  }

  &__content {
    margin-top: rem(40);

    @include desktop-sm-down {
      margin-top: rem(20);
    }
  }

  &__shop-desc {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(14);
    color: #ffffff;
    margin-top: rem(16);

    @include desktop-sm-down {
      margin-top: rem(8);
    }
  }

  .filters-dropdown {
    @include desktop-sm-down {
      margin-top: rem(40);
    }
  }

  .tabs-block {
    @include desktop-sm-up {
      margin-top: rem(60);
    }
  }

  .visitor-actions {
    margin-top: rem(50);

    @include desktop-sm-down {
      display: none;
    }
  }

  &__excursions {
    max-width: rem(620);

    p {
      font-size: rem(18);
      line-height: 150%;
      font-weight: normal;

      @include desktop-sm-down {
        font-size: rem(14);
      }

      b {
        font-size: rem(22);

        @include desktop-sm-down {
          font-size: rem(14);
        }
      }
    }
  }

  .widget > div:not(:last-child) {
    margin-bottom: rem(15);
  }
  .widget--v2 > div:not(:last-child) {
    margin-bottom: rem(30);
  }
  


  .widget h3 {
    margin-bottom: rem(17);
    color: #123063;
  }
  .widget--v2 h3 {
    margin-bottom: rem(34);
  }

  &__for-media {

    h3 {
      margin: rem(28) 0;
    }

    p {
      font-size: rem(18);
      line-height: 150%;
      font-weight: normal;

      @include desktop-sm-down {
        font-size: rem(14);
      }
    }

    .btn {
      min-width: rem(230);
      margin-top: rem(30);
      padding: 0 rem(35);

      @include desktop-sm-down {
        margin-top: 0;
        display: flex;
        max-width: rem(300);
      }
    }
  }

  &__advertisers {

    h3 {
      margin: rem(28) 0;
    }
    &--v2 h3 {
      margin-bottom: rem(42);
    }

    p {
      font-size: rem(18);
      line-height: 150%;
      font-weight: normal;
      margin: 0;

      @include desktop-sm-down {
        font-size: rem(14);
      }
    }

    .btn {
      min-width: rem(230);
      margin-top: rem(35);
      padding: 0 rem(35);

      @include desktop-sm-down {
        margin-top: rem(16);
        display: flex;
        max-width: rem(400);
        padding: 0 rem(15);
      }
    }
    &--v2 .btn {
      margin-top: rem(49);
    }
  }

  &__vip-lodges {
    p {
      @include desktop-sm-up {
        font-size: rem(24);
        max-width: rem(585);
      }
    }
  }

  
  @import "includes/hero-section/&__img";
  @import "includes/hero-section/blur-el";
  @import "includes/hero-section/&_type";
}

@import "includes/hero-section/min-h";
@import "includes/hero-section/filters-block";
@import "includes/hero-section/filters-dropdown";
@import "includes/hero-section/tabs-block";
