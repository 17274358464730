.sheme {
    &-section {
        margin: rem(70) 0;
        padding: rem(66) rem(100) rem(84) rem(100);
        background: #EFF5FB;
        border-radius: rem(30);
        &.pd0 {
            padding: 0;
        }

        @include desktop-sm-down {
            margin: rem(35) 0;
            padding: rem(48) rem(40);
            border-radius: rem(20);
        }

        @include tablets-down {
            margin-left: rem(-10);
            margin-right: rem(-10);
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }

    &__cols {
        margin-top: rem(60);
        display: grid;
        grid-template-columns: 1.9fr 1fr;
        grid-gap: rem(80) 40px;
        &-right {
            grid-row: span 2;
        }
        @include tablets-down {
            grid-template-columns: 1fr;
            grid-gap: rem(50);
            &-right {
                grid-row: initial;
            }
        }
    }   
    &__photo {
        @include tablets-down {
            text-align: center;
            img {
               max-height: 400px;
               margin: 0 auto;
            }
        }
    }
    &__designations {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-25) rem(-26);
        &-item {
            display: flex;
            align-items: center;
            font-size: rem(20);
            line-height: 1.2;
            letter-spacing: 0.02em;
            margin: 0 rem(25) rem(26);
            b {
                font-weight: 600;
            }
            img {
                margin-right: rem(24);
            }
        }

        @include tablets-down {
            margin: 0 rem(-25) rem(-14);
            
            &-item {
                font-size: rem(15);
                margin: 0 rem(25) rem(14);
            }
        }
    }

    &__loccol {
        display: grid;
        grid-template-columns: 1fr 1fr;
        &-info {
            padding: rem(87) rem(30) rem(87) rem(100);
        }
        &-photo {
            position: relative;
            overflow: hidden;
            border-radius: rem(30);
        }
        &-items {
            margin-top: rem(60);
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: rem(20) rem(16);
        }
        &-item {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            grid-gap: 20px;
            font-weight: 600;
            font-size: rem(16);
            line-height: 130%;
            &__icon {
                height: rem(49); width: rem(65);
                img {
                    max-height: 100%; max-width: 100%;
                }
            }
            .light {
                margin-top: 1px;
                font-size: rem(14);
                font-weight: normal;
            }
        }

        @include desktop-sm-down {
            grid-template-columns: 1fr;
            &-info {
                padding: rem(48) rem(40) rem(48) rem(40);
            }
            &-photo {
                border-radius: rem(20);
                padding-bottom: rem(48);
                img {
                    max-width: 600px;
                    width: 100%;
                    border-radius: rem(20);
                    margin: 0 auto;
                }
            }
        }
        @include tablets-down {
            &-info {
                padding: rem(48) rem(10);
            }
            &-items {
                margin-top: rem(25);
                grid-gap: rem(15) rem(15);
                &--mobrow {
                    grid-template-columns: 1fr;
                }
            }
            &-item {
                grid-gap: 15px;
                font-size: rem(14);
                &__icon {
                    height: rem(30); width: auto;
                    
                }
                .light {
                    font-size: rem(12);
                }
            }
            &-photo {
                padding-bottom: rem(0);
                border-radius: 0;
                img {
                    max-width: 100%;
                    border-radius: 0;
                }
            }
        }
    }

}