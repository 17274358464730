.input-wrap {
  margin-bottom: rem(12);
  position: relative;

  @include desktop-sm-down {
    margin-bottom: rem(10);
  }

  + .btn {
    margin-top: rem(30);

    @include desktop-sm-down {
      margin-top: rem(17);
    }
  }
}

.input {
  width: 100%;
  background: #FFFFFF;
  border: rem(1) solid #9BC1D7;
  box-sizing: border-box;
  border-radius: rem(20);
  padding: 0 rem(35);
  padding-right: rem(80);
  font-family: inherit;
  font-weight: 600;
  font-size: rem(14);
  color: #123063;
  height: rem(60);
  transition: .3s;

  @include desktop-sm-down {
    height: rem(50);
    padding: rem(15);
    padding-right: rem(70);
    font-size: rem(12);
    border-radius: rem(15);
  }


  &::placeholder {
    color: #9BC1D7;
  }

  &:focus {
    outline: none;
    border-color: #123063;
  }

  &.active {
    background: #EDF6FC;
    border-color:  #123063;
  }

  &.error {
    border-color: #EF577F;
    background: none;
  }
}

.input-item {
  position: relative;
}

.input-notify {
  font-weight: bold;
  font-size: rem(12);
  line-height: rem(15);
  margin-top: rem(5);
  display: none;
  margin-bottom: rem(20);

  &.error {
    color: #EF577F;
  }
}

.input-clear {
  position: absolute;
  top: 50%;
  right: rem(40);
  transform: translateY(-50%);
  font-size: rem(13);
  color: #89B6E3;
  transition: .3s;

  .icon {
    display: block;
  }

  &:hover {
    color: #123063;
  }
}

.input-toggle {
  position: absolute;
  top: 50%;
  right: rem(30);
  transform: translateY(-50%);
  font-size: rem(22);
  color: #9BC1D7;
  transition: .3s;

  @include desktop-sm-down {
    font-size: rem(14);
  }

  &__tooltip {
    padding: rem(11) rem(13) rem(11);
    background: #123063;
    border-radius: rem(10);
    position: absolute;
    top: rem(-13);
    left: 50%;
    transform: translate(-50%, -100%);
    font-weight: 600;
    font-size: rem(10);
    line-height: rem(13);
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    min-width: rem(112);

    &::after {
      content: "";
      display: block;
      width: 0; 
      height: 0;
      border-left: rem(4) solid transparent;
      border-right: rem(4) solid transparent;
      border-top: rem(6) solid #123063;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
    }
  }

  .icon {
    display: block;
  }



  &:hover {
    color: #123063;

    .input-toggle__tooltip {
      opacity: 1;
    }
  }
}

.form {
  &__action-row  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(28);
    margin-bottom: rem(23);

    @include desktop-sm-down {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }
  }

  &__action-link {
    font-weight: 600;
    font-size: rem(14);
    line-height: 150%;
    color: #00A3DA;

    &:hover {
      text-decoration-line: underline;
      color: #0194D8;
    }
  }

  &__btn-submit {
    width: 100%;
    display: flex;

    @include desktop-sm-down {
      height: rem(50);
    }

    &:hover {
      background: #0194D8;
    }

    + .form__agreement:not(:first-child) {
      margin-top: rem(30);
    }

    + .form__agreement:not(:last-child) {
      margin-bottom: rem(25);
    }

    + .form__btn-registry:not(:first-child) {
      @include desktop-sm-down {
        margin-top: rem(7);
      }
    }
  }

  &__btn-registry {
    + .form__agreement {
      @include desktop-sm-down {
        margin-top: rem(7);
      }
    }
  }

  &__agreement {
    font-size: rem(12);
    line-height: 140%;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(10);
    }

    a {
      color: #0194d8;
      
      &:hover {
        color: #0194d8;
        text-decoration: underline;
      }
    }
  }

  &__btn-registry {
    background: #EDF6FC;
    color: #00A3DA;
    display: flex;
    width: 100%;

    @include desktop-sm-down {
      height: rem(50);
    }

    &:hover {
      background: #EDF6FC;
      color: #00A3DA;
    }

    + p {
      @include desktop-sm-down {
        margin-top: rem(13);
        margin-bottom: rem(13);
      }
      
    }
  }
}


.check-input {
	position: relative;
	cursor: pointer;
	display: flex;

	&__control {
		position: absolute;
		opacity: 0;
		overflow: hidden;
		visibility: hidden;

		&:checked {
			& + .check-input__control-fake {
				border-color: #00A3DA;
        background-color: #00A3DA;

				.icon {
					opacity: 1;
				}
			}
		}
	}

	&__control-fake {
		width: rem(20);
		height: rem(20);
		border: rem(1) solid #9BC1D7;
		transition: border 0.3s;
    margin-right: rem(16);
    border-radius: rem(5);
    font-size: rem(13);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: block;
      opacity: 0;
    }

	}

	&__label {
		font-weight: normal;
		padding: rem(2) 0;
		font-size: rem(14);
		line-height: rem(17);
		color: #000000;
		transition: color 0.3s;

		a {
			color: inherit;
			text-decoration: underline;

			&:hover{
				color: #0B63FF;
			}
		}
	}

  &.radio {

    input {
      &:checked {
        .check-input__control-fake {
          &::after {
            display: block;
          }
        }
      }
    }
    .check-input__control-fake {
      border-radius: 50%;
      position: relative;

      &::before {
        border-radius: 50%;
      }

      &::after {
        content: "";
        display: block;
        width: rem(8);
        height: rem(8);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
}

.sex-input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__title {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: #123063;
    margin-right: rem(36);
  }

  &__inputs {
    display: flex;
    align-items: center;

  }

  .check-input {
    &:not(:last-child) {
      margin-right: rem(32);
    }
  }
}

@import "includes/form/form-entry";