.shop-desc {
  padding: rem(40) rem(80);
  display: flex;
  background: #eff5fb;
  border-radius: rem(30);

  @include desktop-sm-down {
    padding: rem(30) rem(18);
    position: static;
    font-size: rem(12);
    flex-direction: column;
  }

  h6 {
    margin-bottom: rem(40);
    margin-top: 0;
    font-weight: bold;
    font-size: rem(18);
    line-height: 150%;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(16);
      margin-bottom: rem(18);
    }
  }

  &__overflow {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__map {
    @include desktop-sm-up {
      width: rem(230);
      height: rem(230);
      margin-left: rem(160);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem(22);
      flex-shrink: 0;
      background: url("../img/images/map.jpg") no-repeat 50% / cover;
      overflow: hidden;
    }

    @include desktop-sm-down {
      margin-top: rem(16);
    }

    .btn {
      height: rem(50);
      color: #ffffff;
      font-size: rem(14);
      width: 100%;
    }
  }

  &__overflow-btn {
    font-weight: 600;
    font-size: rem(14);
    line-height: 150%;
    margin-top: rem(7);
    color: #00a3da;

    @include desktop-sm-up {
      display: none;
    }
  }

  @include desktop-sm-down {
    &__overflow {
      position: relative;
      max-height: rem(225);
      overflow: hidden;

      &.show {
        max-height: 100%;

        &:after {
          display: none;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: rem(55);
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(239, 245, 251, 0) 0%,
          #eff5fb 100%
        );
      }
    }
  }
}
