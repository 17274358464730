.filters-dropdown {
  position: relative;
  z-index: 1;

  @include desktop-sm-up {
    display: none;
  }

  &.active {
    .icon-chevron-down {
      transform: rotate(180deg);
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(4) rem(5);
    height: rem(50);
    transition: 0.3s;
    border-radius: rem(15);
    background-color: #fff;
  }

  &__title {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(14);
    color: #123063;
    margin-right: auto;
    padding: 0 rem(15);
  }

  .btn {
    width: rem(48);
    height: rem(42);

    .icon-cross {
      font-size: rem(14);
    }

    .icon-chevron-down {
      font-size: rem(15);
    }
  }

  &__btn-clear {
    background: #ebf4f9;
    color: #9bc1d7;
    margin-right: rem(4);
  }

  &__btn-dropdown {
    background: #123063;
    color: #ffffff;
  }

  &__main {
    padding: 0 rem(20);
    position: absolute;
    top: 0;
    padding-top: rem(50);
    width: 100%;
    z-index: -1;
    border-radius: rem(15);
    background: #EDF6FC;
    display: none;

    ul {
      padding: rem(20) 0;

      li {
        cursor: pointer;
        font-weight: 600;
        font-size: rem(14);
        line-height: 1;
        color: #123063;
        padding: rem(10) 0;
      }
    }
  }
}
