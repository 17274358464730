.card-d {
  display: block;

  &:hover {
    .card-d__img {
      &:before {
        background-image: url("../img/images/storis-border_hover.svg");
      }
    }
  }

  &__img {
    width: rem(150);
    height: rem(150);
    padding: rem(12);
    border-radius: 50%;
    margin: 0 auto;
    box-sizing: border-box;
    transition: 0.3s;
    position: relative;
    z-index: 1;

    @include desktop-sm-down {
      width: rem(100);
      height: rem(100);
      border-width: rem(3);
      padding: rem(8);
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-image: url("../img/images/storis-border.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transition: .3s;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 50%;
    }
  }

  &__title {
    font-size: rem(18);
    line-height: 110%;
    text-align: center;
    color: #ffffff;
    margin: rem(25) 0;

    @include desktop-sm-down {
      font-size: rem(12);
    }
  }
}
