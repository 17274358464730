.page-cover {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-15) rem(-30);

    @include desktop-sm-down {
      flex-direction: column;
      margin: 0 rem(-$container-padding-md) rem(10);
    }

    &.not-content {
      margin-bottom: 0;
    }

    &.row_size_1 {
      .page-cover__col {
        &:nth-child(1) {
          @include desktop-sm-up {
            width: calc(100% - #{rem(390)});
          }
        }
    
        &:nth-child(2) {
          @include desktop-sm-up {
            width: rem(390);
          }
        }
      }
    }

    
  }

  &__col {
    width: 100%;
    padding: 0 rem(15);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      padding: 0 rem($container-padding-md);
    }

    &:nth-child(1) {
      @include desktop-sm-up {
        width: calc(100% - #{rem(520)});
      }
    }

    &:nth-child(2) {
      @include desktop-sm-up {
        width: rem(520);
      }

      @include desktop-sm-down {
        order: 10;
      }
    }

    .request-block {
      margin-bottom: 0;
    }
  }

  .article-cover {
    max-width: rem(880);
  }

  .scheme-section {
    margin-top: rem(60);
    margin-bottom: 0;

    @include desktop-sm-down {
      margin-top: 0;
    }
  }

  &_shop {

    .page-cover__row {
      @include desktop-sm-down {
        margin-bottom: rem(-10);
      }
    }

    .page-cover__col {
      position: relative;
      z-index: 2;

      @include desktop-sm-down {
        margin-bottom: rem(10);
      }

      &:first-child {
        > div {
          @include desktop-sm-down {
            overflow: auto;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            margin: 0 rem(-$container-padding-md);
          }
        }

        @include desktop-sm-down {
          margin-bottom: rem(-20);
          z-index: 1;
        }
      }

      > div {
        height: 100%;
        overflow: hidden;

        @include desktop-sm-down {
          border-radius: rem(20);
        }
      }
    }
  }

  &__article {
    max-width: rem(880);
    margin: rem(35) 0;

    &:last-child {
      margin-bottom: 0;
    }

    @include desktop-sm-down {
      margin-top: 0;
    }
  }

  .photo-gallery {
    @include desktop-sm-up {
      margin-bottom: rem(150);
    }

    @include desktop-sm-down {
      margin-bottom: 0;
    }
  }
}
