.page-content {
  margin-top: rem(50);
  margin-bottom: rem(100);

  @include desktop-sm-down {
    margin-top: rem(5);
    margin-bottom: rem(35);
  }
  
  .gallery-item-section {
    position: relative;
    z-index: 10;
  }

  .calendar-block {
    &:first-child {
      @include desktop-sm-down {
        padding-top: rem(10);
      }
    }
  }
}

.for-media-content {
  &__top-swiper {
    margin-bottom: rem(80);

    @include desktop-sm-down {
      margin-bottom: rem(40);
    }
  }
}

.vip-content {
  .main-swiper {
    @include desktop-sm-down {
      margin-bottom: rem(40);
    }
  }
}