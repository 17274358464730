.select2-container .select2-selection--single {
  height: rem(60);
  outline: none;

  @include desktop-sm-down {
    height: rem(50);
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: rem(60);
  width: 100%;
  display: block;
  padding: 0;

  @include desktop-sm-down {
    line-height: rem(50);
  }
}

.select2-container--default .select2-selection--single {
  border: rem(1) solid #9BC1D7;
  border-radius: rem(20);
  padding: 0;

  @include desktop-sm-down {
    border-radius: rem(15);
  }
}

.select2 {
  width: 100%;
}

.select2-container--focus,
.select2-container--open {
  .select2-selection {
    border-color: #123063;
  }
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #9BC1D7;
  line-height: 1;
}


.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0 rem(35);
  padding-right: rem(65);
  font-weight: 600;
  font-size: rem(14);
  line-height: rem(60);
  width: 100%;
  display: block;
  color: #123063;

  @include desktop-sm-down {
    height: rem(50);
    padding: 0 rem(15);
    padding-right: rem(70);
    font-size: rem(12);
    line-height: rem(50);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: rem(50);
  height: rem(50);
  background: #F0F6FB;
  border-radius: rem(20);
  display: flex;
  align-items: center;
  justify-content: center;
  top: rem(5);
  right: rem(5);

  @include desktop-sm-down {
    border-radius: rem(10);
    width: rem(40);
    height: rem(40);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
  background: url("../img/icons/chevron-down.svg") no-repeat 50% / contain;
  width: rem(13);
  height: rem(8);
  transform: translate(-50%, -50%);
  margin: 0;
}

.select2-container--open .select2-selection__arrow b {
  transform: translate(-50%, -50%) rotate(180deg) !important;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: rem(20);
  outline: none;

  @include desktop-sm-down {
    border-radius: rem(15);
  }
}



.select2-dropdown {
  margin-top: rem(12);
  border-radius: rem(20);
  background: #FFFFFF;
  box-shadow: rem(0) rem(4) rem(13) rgba(145, 201, 227, 0.33);
  border-radius: rem(20);
  border: none;
}

.select2-container--open .select2-dropdown--below {
  border-radius: rem(20);
}

.select2-container--default .select2-results>.select2-results__options {
  padding: 0 rem(13);
}

.select2-results__option {
  padding: rem(23);
  font-weight: 600;
  font-size: rem(14);
  line-height: rem(18);
  color: #123063;

  @include desktop-sm-down {
    padding: rem(15);
    font-size: rem(12);
  }

  &:not(:last-child) {
    border-bottom: rem(2) solid #E0F3FF;
  }
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background: none;
  color: #0194D8;
}

.select2-container--default .select2-results__option--selected {
  background: none;
  color: #0194D8;
}


.select2-hidden-accessible.active {
  + .select2 {
    .select2-selection {
      background: #edf6fc;
    }
  }
}