.about-block {
  border-radius: rem(30);
  color: #fff;
  position: relative;
  overflow: hidden;

  @include desktop-sm-down {
    border-radius: 0;
  }
  
  &__img {
    z-index: 1;
    
    @include desktop-sm-up {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @include desktop-sm-down {
      position: relative;
      border-radius: rem(15);
      overflow: hidden;
      padding-top: 65.7%;
      margin-bottom: rem(30);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #1A1F4E 0%, rgba(26, 31, 78, 0) 35.05%, #1A1F4E 79.31%);
      opacity: 0.56;
      z-index: 2;
      pointer-events: none;
    }
  
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #8B95ED;
      opacity: 0.56;
      mix-blend-mode: multiply;
      z-index: 2;
      pointer-events: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      z-index: 1;
    }
  }

  .blur-1 {
    pointer-events: none;
    width: rem(481);
    height: rem(358);
    border-radius: 50%;
    background: rgba(16, 195, 255, 0.67);
    filter: blur(rem(150));
    position: absolute;
    left: rem(864);
    bottom: rem(-200);
    z-index: 2;
  
    @include desktop-sm-down {
      display: none;
    }
  }

  .blur-2 {
    pointer-events: none;
    width: rem(796);
    height: rem(595);
    border-radius: 50%;
    background: #4A36C6;
    filter: blur(rem(150));
    position: absolute;
    left: rem(1146);
    bottom: rem(-320);
    z-index: 3;
  
    @include desktop-sm-down {
      display: none;
    }
  }


  .blur-3 {
    pointer-events: none;
    width: rem(930);
    height: rem(761);
    border-radius: 50%;
    background: #4A36C6;
    filter: blur(rem(150));
    position: absolute;
    left: rem(-296);
    bottom: rem(-440);
    z-index: 2;
  
    @include desktop-sm-down {
      display: none;
    }
  }

  &__content  {
    position: relative;
    z-index: 2; 
  }

  &__cover {
    padding-top: rem(130);
    padding-bottom: rem(90);
    min-height: rem(920);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include desktop-sm-down {
      padding: 0;
      min-height: 0;
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    max-width: rem(670);
    min-height: rem(232);

    @include desktop-sm-down {
      font-weight: 600;
      font-size: rem(24);
      line-height: 130%;
      margin-bottom: rem(20);
      min-height: 0;
    }
  }

  .list-rhombus {
    margin-left: auto;
    display: inline-flex;

    @include desktop-sm-down {
      margin-bottom: rem(40);
      margin-left: 0;
    }

    li {

      @include desktop-sm-up {
        font-size: rem(28);
        line-height: 170%;
      }
    }
  }

  .tabs-block {
    .btn {
      border-color: #fff;

      @include desktop-sm-down {
        background-color: #fff;
        height: rem(60);
        border: rem(2) solid #FFFFFF;
        background: none;
        border-radius: rem(15);
        padding: 0 rem(35);
        font-size: rem(16);

        &:not(:last-child) {
          margin-right: rem(14);
          border-right: rem(2) solid #FFFFFF;
        }

      }

      &:hover {
        color: #00a3da;
        background-color: #fff;
      }
    }
  }
}