.parking-section {
  &__section {
    margin-top: rem(55);

    @include desktop-sm-down {
      margin-top: rem(30);
    }

    h3 {
      margin-top: 0;
      margin-bottom: rem(30);
      line-height: 130%;

      @include desktop-sm-down {
        margin-bottom: rem(25);
        font-size: rem(24);
      }
    }
  }

  @import "includes/parking-section/&__time-block";
  @import "includes/parking-section/&__rules";
}

@import "includes/parking-section/rules-client";
@import "includes/parking-section/rules-item";
