.section-head {
  display: flex;
  align-items: center;

  h1,
  h2 {
    margin: 0;
  }

  &__title {

    &:hover {
      .icon-chevron-right {
        margin-left: rem(45);
      }
    }

    .icon-chevron-right {
      margin-left: rem(30);
      font-size: rem(15);
      transition: .3s;

      @include desktop-sm-down {
        font-size: rem(7);
        margin-bottom: rem(2);
        margin-left: rem(15);
      }
    }

    &.lg {
      > h1, h2 {
        @include desktop-sm-up {
          font-size: rem(72);
        }
      }
    
      .icon-chevron-right {
        margin-left: rem(50);

        @include desktop-sm-down {
          margin-left: rem(25);
          display: none;
        }
      }

      &:hover {
        .icon-chevron-right {
          margin-left: rem(65);

          @include desktop-sm-down {
            margin-left: rem(40);
          }
        }
      }
    }
  }

}
