.advertisers-section {
  margin: rem(60) 0;

  @include desktop-sm-down {
    margin: rem(30) 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    max-width: rem(880);
  }

  &__title {
    margin-bottom: rem(30)
  }

  &__file-links {
    &:not(:last-child) {
      margin: rem(70) 0;

      @include desktop-sm-down {
        margin: rem(30) 0;
      }
    }

    &:last-child {
      margin-top: rem(70);

      @include desktop-sm-down {
        margin-top: rem(30);
      }
    }
  }

  .file-link {
    position: relative;
    z-index: 2;
  }

  .list-rhombus {
    margin-top: rem(40);
    margin-bottom: rem(30);

    @include desktop-sm-down {
      margin: rem(30) 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      @include desktop-sm-down {
        flex-direction: column;
      }

      li {
        font-weight: bold;
        margin-right: rem(110);

        @include desktop-sm-down {
          margin-right: 0;
        }
      }
    }
  }

  .article-swiper {
    @include desktop-sm-up {
      filter: drop-shadow(0 0 rem(150) rgba(#0D4D8F, 0.5));
    }

    &:not(:last-child) {
      margin: rem(70) 0;

      @include desktop-sm-down {
        margin: rem(30) 0;
      }
    }

    &:last-child {
      margin-top: rem(70);

      @include desktop-sm-down {
        margin-top: rem(30);
      }
    }
  }
  
}

.advertisers {
  &__block {
    background: #EFF5FB;
    border-radius: rem(30);
    padding: rem(67) rem(130) rem(80);
    &-intro {
      color: #00A3DA;
      font-weight: 700;
      font-size: rem(14);
      line-height: 170%;
      margin-bottom: rem(20);
    }
    &-title {
      font-weight: 700;
      font-size: rem(40);
      line-height: 130%;
      letter-spacing: 0.02em;
      color: #123063;
      margin-bottom: rem(40);

    }
    &-content {
      max-width: rem(1010);
      font-size: rem(20);
      line-height: 170%;
      font-weight: 300;
    }
    &-mt {
      margin-top: rem(70);
    }
    &-subtitle {
      margin-bottom: rem(60);
      font-weight: 600;
      font-size: rem(24);
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #123063;
    }
    &-list {
      column-gap: rem(36);
      column-count: 2;
      li {
        position: relative;
        padding-left: rem(27);
        font-weight: 300;
        font-size: rem(20);
        line-height: 170%;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        break-inside: avoid-column;
        &:before {
          content: '';
          width: rem(9);
          height: rem(9);
          position: absolute;
          left: 0; top: rem(13);
          background: #00A3DA;
          transform: rotate(45deg);
        }
      }
    }
    &-cols {
      display: grid;
      grid-template-columns: 1fr rem(485);
      grid-gap: rem(130);
      align-items: start;
    }
    &-files {
      background: #F7FBFF;
      border-radius: rem(15);
      padding: rem(39) rem(63);
      .file-link__ico {
        margin-bottom: rem(24);
      }
      .file-link__title {
        line-height: 150%;
      }
      .file-link__btn {
        margin-top: rem(32);
      }
    }
    &-photos {
      margin-top: rem(75);
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(30);
      &--1-2 {
        grid-template-columns: 1fr 1.5fr;
      }
      &__item {
        position: relative;
        overflow: hidden;
        border-radius: rem(30);
        height: rem(400);
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: 50% 50%;
          object-position: 50% 50%;
        }
      }
    }

      
    
    @include desktop-sm-down {
      border-radius: rem(20);
      padding: rem(30) rem(18) rem(30);
      &-intro {
        font-size: rem(12);
        margin-bottom: rem(6);
      }
      &-title {
        font-size: rem(26);
        margin-bottom: rem(24);
  
      }
      &-content {
        max-width: 700px;
        font-size: rem(15);
      }
      &-mt {
        margin-top: rem(36);
      }
      &-subtitle {
        margin-bottom: rem(30);
        font-size: rem(18);
      }
      &-list {
        column-gap: rem(20);
        column-count: 2;
        li {
          padding-left: rem(20);
          font-size: rem(15);
          &:before {
            content: '';
            width: rem(7);
            height: rem(7);
            top: rem(10);
          }
        }
      }
      &-cols {
        grid-template-columns: 1fr;
        grid-gap: rem(30);
      }
      &-files {
        max-width: 600px;
        padding: rem(14) rem(18);
        display: flex;
        align-items: center;
        .file-link__btn {
          margin-top: 0;
        }
      }
      &-photos {
        margin-top: rem(30);
        grid-gap: rem(10);
        &__item {
          border-radius: rem(20);
          height: rem(240);
        }
      }
    }

    
    @include tablets-down {
      padding: rem(30) rem(10) rem(30);
      margin-left: rem(-10);
      margin-right: rem(-10);
    }

    @include tablets-sm-down {
      &-list {
        column-gap: rem(0);
        column-count: 1;
      }
      &-photos {
        grid-template-columns: 1fr !important;
      }
    }
  }

  &__slider {
    @include tablets-down {
      margin-left: rem(-10);
      margin-right: rem(-10);
    }
  }

  &__textadv {
    padding: rem(98) rem(130) rem(93);
    background: #0D4D8F;
    border-radius: rem(30);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-widget {
      width: rem(490);
    }
    &-content {
      width: calc(100% - rem(490) - rem(120));
      max-width: 660px;
      margin-right: rem(120);
      color: #fff;
      font-weight: 300;
      font-size: rem(20);
      line-height: 170%;
    }
    &-title {
      font-weight: 700;
      font-size: rem(40);
      line-height: 130%;
      letter-spacing: 0.02em;
    }
    &-text {
      margin-top: 13px;
    }

    @include desktop-sm-down  {
      
      padding: rem(40) rem(18) rem(60);
      margin: rem(20) 0 rem(20);
      border-radius: rem(20);
      &-content {
        font-size: rem(15);
        width: 100%;
        margin: 0;
        max-width: 100%;
      }
      &-title {
        font-size: rem(24);
      }
      &-text {
        margin-top: rem(29);
      }
    }

    @include tablets-down {
      padding: rem(40) rem(10) rem(60);
      margin: rem(20) rem(-10) rem(20);
    }
  }   
  
  
}