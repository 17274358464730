.card-i {
  background: #EFF5FB;
  border-radius: rem(30);
  overflow: hidden;

  @include desktop-sm-down {
    border-radius: rem(20);
  }

  &:hover {
    .card-i__img img {
      transform: scale(1.2);
    }
  }

  &__img {
    padding-top: 51%;
    position: relative;
    overflow: hidden;

    @include desktop-sm-down {
      padding-top: 48%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 3s;
    }
  }

  &__main {
    padding: rem(30) rem(40);

    @include desktop-sm-down {
      padding: rem(18) rem($container-inner-padding-md);
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem(26);
    line-height: 130%;
    color: #123063;
    margin-bottom: rem(15);

    @include desktop-sm-down {
      font-size: rem(18)
    }
  }

  &__date {
    font-style: italic;
    font-size: rem(14);
    line-height: rem(18);
    color: #191C44;
    margin-bottom: rem(20);

    @include desktop-sm-down {
      font-size: rem(12);
    }
    
    .dot {
      color: #00A3DA;
    }
  }

  &__content {
    margin: rem(20) 0;
    font-weight: 300;
    font-size: rem(16);
    line-height: 150%;
    color: #123063;

    @include desktop-sm-down {
      font-size: rem(14);
    }

    .list-rhombus {
      li {
        display: block;
        font-size: inherit;

        &:before {
          margin-right: rem(5);

        }
      }
    }
  }

  .btn {
    font-size: rem(14);
    height: rem(50);
    padding: 0 rem(17);
    position: relative;
    z-index: 2;

    @include desktop-sm-down {
      width: 100%;
      display: flex;
    }

    .icon {
      font-size: rem(15);
      margin-right: rem(14);
    }
  }

  a.card-i__title {
    display: block;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}