.with-fancybox {
  .header,
  .header-mob {
    z-index: 2000;
  }
}

.container-default {
  z-index: 90;
  
  .fancybox__backdrop {
    background: rgba(14, 18, 72, 0.8);
  }

  .popup__inner {
    background: none;
    padding: 0;
  }

}

.container-custom {
  z-index: 90;
  
  .fancybox__backdrop {
    background: rgba(14, 18, 72, 0.9);

    @include desktop-sm-down {
      background: #fff;
    }
  }

  .fancybox__slide {
    padding: 0;
    padding-top: rem(170);

    @include desktop-sm-down {
      padding-top: rem(60);
    }
  }

  .fancybox__slide::before {
    display: none;
  }
}



.fancybox__content>.carousel__button.is-close {
  top: 0;
  right: rem($container-padding-lg);
  width: auto;
  height: auto;

  @include desktop-sm-down {
    display: none;
  }
}

.carousel__button svg {
  width: rem(30);
  height: rem(30);
  stroke-width: rem(4);
  stroke-linecap: unset;
}

.popup {
  padding: 0;
  width: 100%;
  background: none;
  padding-top: rem(60);

  margin-top: auto;
  margin-bottom: auto;

  @include desktop-sm-down {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__inner {
    background: #FFFFFF;
    border-radius: rem(15);
    margin: 0 auto;
    padding: rem(50) rem(40);
    position: relative;
    overflow: hidden;

    @include desktop-sm-down {
      border-radius: 0;
      padding: rem(15) rem($container-padding-md) rem($bottomNav-h + 20);
    }
  }

  &__row {
    display: flex;

    @include desktop-sm-down {
      flex-direction: column-reverse;
    }
  }

  &__col {
    width: 50%;
    position: relative;

    @include desktop-sm-down {
      width: 100%;
    }

    &.col-img {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        transition: 3s;
      }
  
      @include desktop-sm-down {
        padding-top: 50%;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: rem(15);
          background: #ffffff;
          border-radius: rem(15) rem(15) rem(0) rem(0);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }

  &__col-inner {
    padding: rem(50);

    @include desktop-sm-down {
      padding: rem(15) rem($container-padding-md) rem(25);
    }
  }


  .btn-close {
    position: absolute;
    top: rem(5);
    right: rem(5);
    z-index: 5;

    @include desktop-sm-down {
      top: rem(10);
      right: rem(10);
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(30);
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #123063;
    margin-bottom: rem(40);

    @include desktop-sm-down {
      font-size: rem(18);
      line-height: 110%;

      @include desktop-sm-down {
        margin-bottom: rem(20);
      }
    }
  }

  &__desc {
    margin-top: rem(20);
    margin-bottom: rem(30);
    
    p {
      font-size: rem(14);
      line-height: 140%;
    }
    
  }
}

.btn-close {
  width: rem(40);
  height: rem(40);
  background: #EDF6FC;
  border-radius: rem(15);
  color: #123063;
  font-size: rem(11);
  outline: none;
  transition: .3s;

  @include desktop-sm-down {
    width: rem(32);
    height: rem(32);
    font-size: rem(9);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: #D7EDFB;
  }

}

.popup-callback,
.popup-forgot {
  .popup__inner {
    max-width: rem(520);
    width: 100%;
  }
  
}

.popup-forgot {
  .popup__title {
    margin-bottom: rem(20);
  }
}

.popup-registration {
  
  @include desktop-sm-up {
    padding-top: rem(30);
  }
  
  .popup__inner {
    max-width: rem(790);
    width: 100%;
  }

  .sex-input {
    @include desktop-sm-down {
      margin-top: rem(10);
    }
  }

  .col {
    > .check-input {
      margin-right: rem(30);
    }
  }
}

@import "includes/popup/all";

