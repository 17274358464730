.video-section {

}
.video {
    &__wrap {
        position: relative;
        overflow: hidden;
        border-radius: rem(30);
    }
    &__preview {
        padding-bottom: 38%;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: 50% 50%;
            object-position: 50% 50%;
            transition: all 0.3s ease-in-out;
        }    
    }
    .show &__preview img {
        opacity: 0;
        visibility: hidden;
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }
    .show video {
        opacity: 1;
        visibility: visible;
    }
    &__play {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        z-index: 5;
        &-circle {
            position: absolute;
            left: 50%; top: 50%;
            transform: translate(-50%, -50%);
            background: #0194D8;
            border-radius: 100%;
            width: rem(130); height: rem(130);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            svg {
                position: relative;
                left: 3px;
            }
        }
        &:hover &-circle {
            transform: translate(-50%, -50%) scale(0.7);
        }
    }
    .show &__play {
        opacity: 0;
        visibility: hidden;
    }

    @include desktop-sm-down { 
        &__wrap {
            border-radius: rem(20);
        }
        &__preview {
            padding-bottom: 50%;
        }
        &__play {
            &-circle {
                width: rem(66); height: rem(66);
                svg {
                   width: rem(18);
                }
            }
        }
    }
    @include tablets-sm-down {
        &__preview {
            padding-bottom: 60%;
        }
    }
}